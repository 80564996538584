import React, { Component } from 'react';
import JsonData from "../data/data.json";

export class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      symbols_to_render: "",
      h1_content: "",
      isMobile: window.innerWidth <= 768
    };
    this.symbols_arr = [...JsonData.Header.paragraph];
    this.h1_symbols_arr = [...JsonData.Header.title];
  }

  tick = () => {

    if (this.symbols_arr[this.state.seconds]){    

      this.setState((state) => ({
        seconds: state.seconds + 1,
        symbols_to_render: this.symbols_arr[state.seconds] ? state.symbols_to_render + this.symbols_arr[state.seconds] : state.symbols_to_render,
        h1_content: this.h1_symbols_arr[state.seconds] ? state.h1_content + this.h1_symbols_arr[state.seconds] : state.h1_content
      }));

    } else {

      this.componentWillUnmount();
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, 10);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    
    const h1Style = this.state.isMobile ? {fontSize: '30px'} : {fontSize: '55px'};
    const pStyle = this.state.isMobile ? {fontSize: '13px', backgroundColor: '#FFFFFF', borderRadius: '15px', padding: '5px'} : {fontSize: '17px', backgroundColor: '#FFFFFF', borderRadius: '25px', padding: '5px'};

    return (
      <header id='header'>
        <div className='intro'>
          <div className='overlay'>
            <div className='container' >
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                  <h1 style={h1Style}>
                    {this.state.h1_content}
                    <span></span>
                  </h1>
                  <p style={pStyle}>{this.state.symbols_to_render} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
