import { useState } from 'react'
import emailjs from 'emailjs-com'
import{ init } from 'emailjs-com';
import github_icon from '../data/photos/github/github-icon.png';
import phone_icon from "../data/photos/github/phone-icon.png";
import telegram_icon from "../data/photos/github/telegram-icon.png"

init("user_sklvQNCX9AIjZ1VyPrKoJ");


const ContactsInfo = (props, h2Style, pStyle) => {
  
  return (
    <div className='col-md-3 col-md-offset-1 contact-info'>
      <div className='contact-item'>
        <h3 tyle={h2Style}>Contact</h3>
        <p style={pStyle}>
          <span>
            <i className='fa fa-map-marker'></i> Address
          </span>
          {props.data ? props.data.address : 'loading'}
        </p>
      </div>
      <div className='contact-row'>

        <div className='contact-item'>
          <p>
            <span className="center-icon">
              <a href="tel:+34642927687">
                <img src={phone_icon} alt="GitHub Icon" width="52" height="52" />
              </a>
            </span>
          </p>
        </div>

        <div className='contact-item'>
          <p>
            <span className="center-icon">
              <a href="https://github.com/alexandr-masl" target="_blank" rel="noopener noreferrer">
                <img src={github_icon} alt="GitHub Icon" width="52" height="52" />
              </a>
            </span>
          </p>
        </div>

        <div className='contact-item'>
          <p>
            <span className="center-icon">
              <a href="https://t.me/kolobokbolobok" target="_blank" rel="noopener noreferrer">
                <img src={telegram_icon} alt="GitHub Icon" width="52" height="52" />
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const Footer = () => {

  const isMobile = (window.innerWidth <= 768);
  const company = isMobile ? {fontSize: '9px'} : {fontSize: '9px'};

  return (
    <div id='footer'>
        <div className='container text-center'>
          <p style={company}>
            &copy; 2020-2023 Boncodi Solutions SL
          </p>
        </div>
    </div>
  )
}

const ContactForm = () => {

  const [{ name, email, message }, setState] = useState({ name: '', email: '', message: ''})
  const [form_is_submited, setFormState] = useState(false);

  const isMobile = (window.innerWidth <= 768);
  const h2Style = isMobile ? {fontSize: '27px', "margin-top": "15%"} : {fontSize: '32px', "margin-top": "15%"};
  const pStyle = isMobile ? {fontSize: '13px'} : {fontSize: '17px'};

  const handleChange = (e) => {
    const { name, value } = e.target

    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const form_back_to_normal = () => {
    setFormState(false);
  }

  const handleSubmit = (e) => {

    e.preventDefault()
    setFormState(!form_is_submited)

    emailjs.send("service_qo4xnmx","template_nktb7v4",{
      from_name: name,
      to_name: "Alex",
      message: (message) + " | Reply EMAIL: " + email
    });
  }


  return (     
    !form_is_submited ? 
    (
      <div className='col-md-8'>
        <div className='row'>


          <div className='section-title'>
            <h2 style={h2Style}>Get In Touch</h2>
            <p style={pStyle}>
              Please fill out the form below to send us an email and we will
              get back to you as soon as possible.
            </p>
          </div>


          <form name='sentMessage' validate onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className='form-control'
                  placeholder='Name'
                  required
                  onChange={handleChange}
                  style={isMobile ? { fontSize: '11px', padding: '8px', margin: '4px 0' } : {}}
                />
                <p className='help-block text-danger'></p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='form-control'
                  placeholder='Email'
                  required
                  onChange={handleChange}
                  style={isMobile ? { fontSize: '11px', padding: '8px', margin: '4px 0' } : {}}
                />
                <p className='help-block text-danger'></p>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <textarea
              name='message'
              id='message'
              className='form-control'
              rows='4'
              placeholder='Message'
              required
              onChange={handleChange}
              style={isMobile ? { fontSize: '11px', padding: '5px', margin: '4px 0' } : {}}
            ></textarea>
            <p className='help-block text-danger'></p>
          </div>
          <div id='success'></div>
          <button type='submit' className='btn btn-custom btn-lg'>
            Send
          </button>
          </form>
          </div>
      </div>

    )

    :

    (
      <div>
        <div className='col-md-8'>
          <div className='row'>
            <div className='section-title'>
              <h2 style={h2Style}>Thank you for contacting us ✉️</h2>
              <p style={pStyle}> 
                We will get back to you as soon as possible.
              </p>
            </div>
            <form name='sentMessage' validate onSubmit={form_back_to_normal}>
              <button type='submit' className='btn btn-custom btn-lg'>
                OK
              </button>
            </form>
          </div>
        </div>
      </div>
    )

  )
  
}

export const Contact = (props) => {

  const isMobile = (window.innerWidth <= 768);
  const h2Style = isMobile ? {fontSize: '27px', "margin-top": "15%"} : {fontSize: '32px', "margin-top": "15%"};
  const pStyle = isMobile ? {fontSize: '13px'} : {fontSize: '17px'};


  return (
    <div>
      <div id='contact'>
        <div className='container'>

          <ContactForm />

          <ContactsInfo data={props.data} h2Style={h2Style} pStyle={pStyle}/>
          
        </div>
      </div>

      <Footer />

    </div>
  )
}
