import React, { useState, useEffect } from 'react';

export const Services = (props) => {
  const isMobile = (window.innerWidth <= 768);

  const h2Style = isMobile ? {fontSize: '27px'} : {fontSize: '32px'};
  const h3Style = isMobile ? {fontSize: '21px'} : {fontSize: '24px'};
  const pStyle = isMobile ? {fontSize: '13px'} : {fontSize: '13px'};

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2 style={h2Style}>EXPLORE OUR SERVICES</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <i className={d.icon}></i>
                  <div className='service-desc' style={{ backgroundColor: '#FFFFFF', borderRadius: '20px', padding: '1px 20px'}}>
                    <h3 style={h3Style}>{d.name}</h3>
                    <p style={pStyle}>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  );
}
