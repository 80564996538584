import archWayImage from '../data/photos/coins-640.png';
import submarineContract from '../data/photos/stacking-640.png';
import gold_digger_logo from '../data/photos/hanshake-640.png';


import React, { useState, useEffect } from 'react';
import { Modal } from './modal_portfolio';
import JsonData from '../data/data.json';

const photos = [archWayImage, gold_digger_logo, submarineContract]

export const Portfolio_ = () => {
  const isMobile = (window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const h2Style = isMobile ? { fontSize: '27px' } : { fontSize: '32px' };
  const h3Style = isMobile ? { fontSize: '17px' } : { fontSize: '21px'};

  const openModal = (index) => {

    console.log(`Photo URL: ../data/photos/${JsonData.Portfolio[index].photos[0]}`);

    setShowModal(true);
    setCurrentItemIndex(index);
  };


  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2 style={h2Style}>WEB3 PORTFOLIO</h2>
        </div>
        <div className='row'>
          {JsonData.Portfolio.map((item, index) => (
            <div className='col-md-4' key={index}>
              <button className='portfolio-cell-custom' onClick={() => openModal(index)}>
                <img src={photos[index]} alt={item.title} className='avatar-class' />
                <div>
                  <h3 style={h3Style} dangerouslySetInnerHTML={{ __html: item.title }} />
                </div>
              </button>
            </div>
          ))}
        </div>
        {showModal && currentItemIndex !== null && (
          <Modal setShowModal={setShowModal} data={JsonData.Portfolio[currentItemIndex]} />
        )}
      </div>
    </div>
  );
  
};



