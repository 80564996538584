import archWayImage from '../data/photos/astronaut-800.jpeg';
import submarineContract from '../data/photos/astronaut-800.jpeg';
import gold_digger_logo from '../data/photos/astronaut-800.jpeg';
import React, { useState, useEffect } from 'react';


export const Modal = ({ setShowModal, data }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Initialize state for current slide index

  const isMobile = (window.innerWidth <= 768);

  const closeModal = (e) => {
    if (e.target.classList.contains('modal') && e.target.classList.contains('modal-open')) {
      setShowModal(false);
    }
  };

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3); // Assuming you have 3 slides, it loops back to the first
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3); // Loop back to last slide
  };

  const photos = [archWayImage, gold_digger_logo, submarineContract];

  const WIDTH_FORMAT = isMobile ? "300" : "800";
  const h2Style = isMobile ? {fontSize: '20px', padding: "10px"} : {fontSize: '27px', padding: "10px"};
  const pStyle = isMobile ? {fontSize: '9px'} : {fontSize: '13px'};


  return (
    <div className='modal-overlay' onClick={closeModal}>
      <div className='modal modal-open'>
        <div className='modal-content'>
          <span className='close' onClick={() => setShowModal(false)}>&times;</span>

          <div>
            <h2 style={h2Style} dangerouslySetInnerHTML={{ __html: data.title }} />
          </div>

          {/* Slider code */}
          <div className='slider'>
            <div className='slider-images'>
              {
                photos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    width={WIDTH_FORMAT}
                    alt="Current slide"
                    style={{ display: currentIndex === index ? 'block' : 'none',  }}
                  />
                ))
              }
            </div>
              <button onClick={prevSlide} className="slider-button">Back</button>
              <button onClick={nextSlide} className="slider-button">Next</button>
            <p></p>
            <div>
              <p style={pStyle} dangerouslySetInnerHTML={{ __html: data.text }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
